<template>
  <div>
    <v-card :loading="$store.state.loading" elevation="0" class="transparent">
      <v-card-title>
        <h3>الاوردرات</h3>
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" @click="openAddDialog()">اضافة مجموعة</v-btn> -->
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-chip-group active-class="success" v-model="options.filters.synced">
              <v-chip label large filter :value="'noone'">الكل</v-chip>
              <v-chip label large filter :value="false">غير مرحل</v-chip>
              <v-chip label large filter :value="true">تم الترحيل</v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field outlined solo label="بحث حسب الاسم" v-model="options.filters.fullName.$containsi"
              append-icon="mdi-magnify"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field outlined solo label="بحث حسب رقم الهاتف" v-model="options.filters.phone.$containsi"
              append-icon="mdi-phone-message"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field outlined solo label="بحث حسب رقم العنوان" v-model="options.filters.address.$containsi"
              append-icon="mdi-map-marker-account"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table :headers="headers" :ripple="false" :items="orders" hide-default-footer
              :loading="$store.state.loading" no-data-text="لا يوجد" class="" disable-pagination :item-class="rowClass"
              loading-text="جار التحميل...">
              <template v-slot:item.created="{ item }">
                {{ $service.formatDate(new Date(item.createdAt), false) }}
              </template>
              <template v-slot:item.time="{ item }">
                {{ $service.formatDate(new Date(item.createdAt), false, true) }}
              </template>
              <template v-slot:item.since="{ item }">
                {{ $service.since(new Date(item.createdAt)) }}
              </template>
              <template v-slot:item.phone="{ item }">
                <a :href="`tel:${item.phone}`" dir="ltr" class="text-decoration-none">
                  {{ item.phone }}
                  <v-icon color="primary">mdi-phone</v-icon>
                </a>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center">
                  <v-btn color="primary" @click="openDetials(item)" outlined>
                    <v-icon>mdi-eye</v-icon>
                    عرض التفاصيل
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-center">
              <v-pagination v-model="options.pagination.page"
                :length="parseInt(total / options.pagination.pageSize) + 1" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" persistent :overlay="false" max-width="1000px" transition="dialog-transition">
      <v-card :loading="$store.state.loading">
        <v-card-title>
          تفاصيل الاوردر
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-container v-if="dialog">
          <p>
            الاسم الكامل:
            <span class="font-weight-bold">
              {{ order.fullName }}
            </span>
          </p>
          <p>
            رقم الهاتف:
            <span dir="ltr" class="font-weight-bold">
              {{ order.phone }}
            </span>
          </p>
          <p>
            العنوان:
            <span class="font-weight-bold">
              {{ order.address }}
            </span>
          </p>
          <p>
            وصف العنوان الاضافي:
            <span class="font-weight-bold">
              {{ order.nearBy }}
            </span>
          </p>
          <p>
            ملاحظات:
            <span class="font-weight-bold">
              {{ order.note }}
            </span>
          </p>
          <p>
            الاجمالي:
            <span class="font-weight-bold">
              {{ order.order_products.reduce((a, b) => a + b.finalPrice, 0) }}
            </span>
          </p>
          <p>المنتجات:</p>
          <v-row>
            <v-col cols="4" v-for="product in order.order_products" :key="product.id">
              <v-card>
                <v-card-title>
                  <p>{{ product.product.title }}</p>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col style="padding-top: 0px; padding-bottom: 0px" cols="12" md="6">
                      <p>
                        الكمية:
                        <span class="font-weight-bold">
                          {{ product.quantity }}
                        </span>
                      </p>
                    </v-col>
                    <v-col style="padding-top: 0px; padding-bottom: 0px" cols="12" md="6">
                      <p>
                        السعر:
                        <span class="font-weight-bold">
                          {{ product.finalPrice }}
                        </span>
                      </p>
                    </v-col>
                    <v-col style="padding-top: 0px; padding-bottom: 0px" cols="12" v-if="product.additons.length > 0">
                      <p>الاضافات:</p>
                      <v-row>
                        <v-col style="padding-top: 0px; padding-bottom: 0px" v-for="addition in product.additons"
                          :key="addition.id" cols="6">
                          <p>
                            {{ addition.text }}
                            <span class="font-weight-bold">
                              ( +{{ addition.price }} )
                            </span>
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="order.synced" :loading="$store.state.loading" large color="primary" @click="sync">
            <v-icon>mdi-arrow-all</v-icon>
            {{ order.synced ? "تم الترحيل" : "ترحيل" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <audio src="@/assets/sound.mp3" id="sound"></audio> -->
    <audio ref="audio" :src="activeSound" preload id="audio" muted></audio>
    <Dialog />
  </div>
</template>
<script>
const { io } = require("socket.io-client");
import Dialog from './dialog.vue';
var audio = require("@/assets/sound.mp3");
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      orders: [],
      total: 1,
      order: {},
      dialog: false,
      audio,
      activeSound: null,
      headers: [
        { text: "الاسم", value: "fullName" },
        { text: "رقم الهاتف", value: "phone" },
        { text: "العنوان", value: "address" },
        { text: "وصف العنوان", value: "nearBy" },
        { text: "عدد المنتجات", value: "order_products.length" },
        { text: "الوقت", value: "time" },
        { text: "منذ", value: "since" },
        { text: "التاريخ", value: "created" },
        { text: "الاجراءات", value: "actions" },
      ],
      options: {
        populate: "*",
        filters: {
          resturant: {
            id: { $eq: this.$store.state.resturant.id },
          },
          fullName: {
            $containsi: "",
          },
          phone: {
            $containsi: "",
          },
          address: {
            $containsi: "",
          },
          synced: "noone",
        },

        sort: "publishedAt:desc",
        pagination: {
          page: 1,
          pageSize: 15,
        },
      },
    };
  },
  created() {
    this.getnews();
    this.getActiveSound()
    this.$root.$on("refresh", () => {
      this.getnews();
    });
    const SERVER_URL = this.$service.url;
    const JWT_TOKEN = this.$store.state.userData.token;

    // token will be verified, connection will be rejected if not a valid JWT
    const socket = io(SERVER_URL, {
      auth: {
        token: JWT_TOKEN,
      },
    });

    //  wait until socket connects before adding event listeners
    socket.on("connect", () => {
      socket.on("order:create", () => {
        // console.log("new order", data);
        this.getnews();
      });
    });
    socket.emit("connection");
  },
  async mounted() { },
  methods: {
    async getActiveSound() {
      const res = await this.$http.get(
        "/resturants/" + this.$store.state.resturant.id,
        {
          params: {
            populate: {
              selected_sound: {
                populate: '*'
              }
            },
          },
          paramsSerializer: this.$service.qsParams,
        }
      );
      if(res.data.data.selected_sound.audio){
        console.log(res.data.data.selected_sound);
        this.activeSound = this.$service.url + res.data.data.selected_sound.audio.url
      } else {
        this.activeSound = '@/assets/sound.mp3';
      }

    },
    rowClass(item) {
      let row = "error lighten-4";
      if (item.synced) {
        row = "green lighten-4";
      }
      return row;
    },
    async sync() {
      let data = {
        code: this.order.id,
        sub_total: this.order.order_products.reduce(
          (a, b) => a + b.finalPrice,
          0
        ),
        delivery_charge: this.$store.state.resturant.deliveryFee,
        total:
          this.order.order_products.reduce((a, b) => a + b.finalPrice, 0) +
          parseInt(this.$store.state.resturant.deliveryFee),
        name: this.order.fullName,
        phone: this.order.phone.replace(/\s/g, ""),
        address: this.order.address,
        address2: this.order.nearBy,
        remarks: this.order.note,
        items: [],
      };
      this.order.order_products.forEach((product) => {
        let price = product.product.price;
        let erp_code = product.product.erp_code;
        if (product.additons.length > 0) {
          let pricedAdditions = product.additons.filter(
            (addition) => addition.priced
          );
          if (pricedAdditions.length > 0) {
            price += pricedAdditions.reduce((a, b) => a + b.price, 0);
            erp_code = product.additons.filter((addition) => addition.priced)[0]
              .erp_code;
          }
        }
        data.items.push({
          Quant: product.quantity,
          Price: price,
          description:
            product.product.title +
            " " +
            product.additons
              .filter((addition) => addition.priced)
              .map((addition) => addition.text)
              .join(" ") +
            "\n الاضافات: " +
            product.additons
              .filter((addition) => !addition.priced)
              .map((addition) => addition.text)
              .join(" , "),
          item_code: erp_code,
        });
      });
      console.log(data);
      this.$store.commit("setLoading", true);
      try {
        var raw = JSON.stringify(data);
        var myHeaders = new Headers();

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
          mode: "no-cors",
        };
        let res = await fetch(
          this.$store.state.resturant.sync_url + "/PostBill",
          requestOptions
        );
        console.log(res);
        this.order.synced = true;
        await this.$http.put("/orders/" + this.order.id, {
          data: { synced: true },
        });
        this.$store.commit("setLoading", false);
      } catch (e) {
        // console.log(e);
        this.$store.commit("setLoading", false);
      }
    },
    openDetials(item) {
      // this.order = item;
      this.$store.commit('updateItem', item)
      this.$store.state.dialog = true;
      // this.dialog = true;
    },
    playNote() {
      let audio = new Audio(this.audio);
      audio.addEventListener("canplaythrough", () => {
        var resp = this.$refs.audio.play();

        if (resp !== undefined) {
          resp
            .then((_) => {
              console.log(_);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    getnews() {
      this.$store.commit("setLoading", true);
      let options = {
        ...this.options
      }
      if (this.options.filters.synced == 'noone') {
        delete options.filters.synced;
        options.filters.$or = [
          {
            synced: true
          },
          {
            synced: false
          }
        ]
      }
      this.$http
        .get("/orders", {
          params: options,
          paramsSerializer: this.$service.qsParams,
        })
        .then((response) => {
          this.orders = response.data.data;
          this.total = 1;
          this.$store.commit("setLoading", false);
        });
    },
    openAddDialog() {
      this.$store.state.dialog = true;
      this.$store.state.dialogType = "add";
    },
    openEditDialog(item) {
      this.$store.state.item = item;

      this.$root.$emit("fill-fields", item);
      this.$store.state.dialog = true;
      this.$store.state.dialogType = "edit";
    },
  },
  watch: {
    options: {
      handler() {
        this.getnews();
      },
      deep: true,
    },
  },
};
</script>
<style>
p {
  margin: 10px;
}
</style>
