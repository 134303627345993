<template>
  <div>
    <v-dialog
      v-model="$store.state.dialog"
      persistent
      :overlay="false"
      max-width="900px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          تفاصيل الطلب
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="$store.state.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="item != null" class="mt-5">
          <v-form :disabled="disabled" style="position: relative;">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  hide-details=""
                  v-model="item.fullName"
                  outlined
                  label="الاسم الكامل"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  hide-details=""
                  v-model="item.phone"
                  outlined
                  dir="ltr"
                  label="رقم الهاتف"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  hide-details=""
                  v-model="item.address"
                  outlined
                  label="العنوان"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  hide-details=""
                  v-model="item.nearBy"
                  outlined
                  label="وصف العنوان"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  hide-details=""
                  v-model="item.note"
                  outlined
                  label="ملاحظات"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col
                    v-for="orderProduct in item.order_products"
                    :key="orderProduct.id"
                    cols="6"
                    md="4"
                  >
                    <v-card outlined>
                      <v-card-title>
                        {{ orderProduct.product.title }}
                      </v-card-title>
                      <v-card-text>
                        <v-text-field
                          hide-details=""
                          v-model="orderProduct.quantity"
                          outlined
                          label="الكمية"
                          type="number"
                        >
                        </v-text-field>
                        <v-textarea
                          class="mt-2"
                          hide-details=""
                          v-model="orderProduct.note"
                          outlined
                          label="ملاحظات"
                        >
                        </v-textarea>
                        <p v-if="orderProduct.additons.length > 0">الاضافات</p>
                        <v-row
                          class="mt-3"
                          v-if="orderProduct.additons.length > 0"
                        >
                          <v-col
                            v-for="(add, i) in orderProduct.additons"
                            :key="i"
                            cols="3"
                          >
                            {{ add }}
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="sticky">
              <v-divider></v-divider>
              <div class="d-flex justify-space-between mt-2 align-center">
                <v-btn
                  :loading="$store.state.loading"
                  x-large
                  :text="item.synced ? true : false"
                  :color="item.synced ? 'success' : 'primary'"
                  @click="
                    () => {
                      if (!item.synced) sync();
                    }
                  "
                >
                  <v-icon>{{
                    item.synced ? "mdi-check-all" : "mdi-arrow-all"
                  }}</v-icon>
                  {{ item.synced ? "تم الترحيل" : "ترحيل" }}
                </v-btn>
                <div class="d-flex justify-end  ">
                  <v-btn
                    @click="disabled = !disabled"
                    :color="disabled ? 'warning' : 'error'"
                  >
                    <v-icon>{{ disabled ? "mdi-pencil" : "mdi-close" }}</v-icon>
                    {{ disabled ? "تعديل" : "الغاء" }}
                  </v-btn>
                  <v-btn
                    class="mr-2"
                    v-if="!disabled"
                    @click="editItem"
                    color="success"
                  >
                    <v-icon>mdi-content-save</v-icon>
                    حفظ
                  </v-btn>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      item: null,
      disabled: true,
    };
  },
  methods: {
    async sync() {
      let data = {
        code: this.item.id,
        sub_total: this.item.order_products.reduce(
          (a, b) => a + b.finalPrice,
          0
        ),
        delivery_charge: this.$store.state.resturant.deliveryFee,
        total:
          this.item.order_products.reduce((a, b) => a + b.finalPrice, 0) +
          parseInt(this.$store.state.resturant.deliveryFee),
        name: this.item.fullName,
        phone: this.item.phone.replace(/\s/g, ""),
        address: this.item.address,
        address2: this.item.nearBy,
        remarks: this.item.note,
        items: [],
        pic: 'https://maps.google.com/local?q=' + this.item.lat + ',' + this.item.long,
      };
      this.item.order_products.forEach((product) => {
        let price = product.product.price;
        let erp_code = product.product.erp_code;
        if (product.additons.length > 0) {
          let pricedAdditions = product.additons.filter(
            (addition) => addition.priced
          );
          if (pricedAdditions.length > 0) {
            price += pricedAdditions.reduce((a, b) => a + b.price, 0);
            erp_code = product.additons.filter((addition) => addition.priced)[0]
              .erp_code;
          }
        }
        data.items.push({
          Quant: product.quantity,
          Price: price,
          description:
            product.product.title +
            " " +
            product.additons
              .filter((addition) => addition.priced)
              .map((addition) => addition.text)
              .join(" ") +
            "\n الاضافات: " +
            product.additons
              .filter((addition) => !addition.priced)
              .map((addition) => addition.text)
              .join(" , "),
          item_code: erp_code,
        });
      });
      console.log(data);
      this.$store.commit("setLoading", true);
      try {
        var raw = JSON.stringify(data);
        var myHeaders = new Headers();

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
          mode: "no-cors",
        };
        let res = await fetch(
          this.$store.state.resturant.sync_url + "/PostBill",
          requestOptions
        );
        console.log(res);
        this.item.synced = true;
        await this.$http.put("/orders/" + this.item.id, {
          data: { synced: true },
        });
        this.$store.commit("setLoading", false);
      } catch (e) {
        // console.log(e);
        this.$store.commit("setLoading", false);
      }
    },
    async editItem(){
        this.$store.commit("setLoading", true);
        try {
          await this.$http.put("/orders/" + this.item.id, {
            data: this.item,
          });
          this.$store.commit("setLoading", false);
        } catch (e) {
          // console.log(e);
          this.$store.commit("setLoading", false);
        }
        this.disabled = true;
    }
  },
  watch: {
    "$store.state.dialog": {
      handler() {
        this.item = this.$store.state.item;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 3px;
  background-color: white;
  z-index: 1;
}
</style>
